// use lazyload
var lozad = require('lozad')
const observer = lozad(); // lazy loads elements with default selector as '.lozad'
observer.observe();

// Navbar
const navbar = document.getElementById("navbar");
const navbarToggle = navbar.querySelector(".navbar-toggle");

function openMobileNavbar() {
  navbar.classList.add("opened");
  navbarToggle.setAttribute("aria-label", "Close navigation menu.");
}

function closeMobileNavbar() {
  navbar.classList.remove("opened");
  navbarToggle.setAttribute("aria-label", "Open navigation menu.");
}

navbarToggle.addEventListener("click", () => {
  if (navbar.classList.contains("opened")) {
    closeMobileNavbar();
  } else {
    openMobileNavbar();
  }
});



// Flickity Image Carousel
////////////////////////////////////////////////////////
var Flickity = require('flickity');
